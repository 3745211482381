<template>
  <div class="section">
    <div
      class="transaction__filter--container py-2 pl-0 pr-2 justify-content-between"
      style="height: 82px; background-color: #f6f7fa"
    >
      <div class="align-self-center ml-3">
        <h6 class="mb-0 text-dark size16">Laporan</h6>
      </div>
      <FilterReport
        :filter="filter"
        :getProduk="getProduk"
        :optionsProduk="optionsProduk"
        :filterDate="filterDate"
        @startDate="getStartDate"
        @endDate="getEndDate"
        @productUuid="getProductUuid"
        @filterDate="getFilterDateType"
      />
      <div class="d-flex search__export">
        <b-button
          class="border"
          variant="outline-secondary bg-white d-flex align-items-center"
          @click="$bvModal.show('export-report')"
          :disabled="
            !checkPermission([
              'laporan pembayaran ke pemasok',
              'laporan pembayaran lainnya',
              'laporan pembayaran piutang',
              'laporan utang',
              'laporan piutang',
              'laporan pembelian stok gudang',
              'laporan pembelian stok pemasok',
              'laporan kasir',
              'laporan stok aktual',
              'laporan penjualan',
              'laporan laba kotor',
              'kartu stok',
            ])
          "
        >
          <div class="d-flex align-items-center">
            <img
              class="mb-0 mr-25"
              width="20"
              :src="require('@/assets/images/icons/DocumentBlank.svg')"
            />
            <h6 class="mb-0 text-dark">Unduh Laporan</h6>
          </div>
        </b-button>
      </div>
    </div>
    <!-- this section -->
    <!-- <div v-if="isLoadingExport" class="ml-auto mb-1 mx-2 w-25">
      <b-progress max="100" :value="downloadProgress"></b-progress>
      <p class="text-right">Progress: {{ downloadProgress }}%</p>
    </div>     -->
    <b-container
      fluid
      class="pt-lg-2 pb-lg-2"
      style="background-color: #f6f7fa"
      v-if="checkPermission('dashboard')"
    >
      <b-row class="mx-0 mb-2">
        <b-col class="col-lg-0 mb-md-1" cols="6" md="6" lg="3">
          <b-card class="border-10 p-0 h-100" no-body>
            <b-card-body
              class="h-100 d-flex flex-column justify-content-between"
            >
              <div>
                <b-avatar class="mb-1 bg-light bg-lighten-green" size="45">
                  <img
                    src="@/assets/images/icons/income.svg"
                    alt="wallet green"
                  />
                </b-avatar>
                <h6 class="size14 text-dark fw-bold-400">
                  Total Pendapatan (Rp)
                  <feather-icon icon="AlertCircleIcon" />
                </h6>
              </div>
              <div>
                <h1 class="fw-bold-500 size32 text-dark mb-2">
                  {{ sales_stats.total_sales | formatAmount }}
                </h1>
                <div class="d-flex">
                  <h6 class="fw-bold-400 size18 mb-0 text-dark mr-auto">
                    Ritel
                  </h6>
                  <h6 class="fw-bold-500 size18 text-success fw-bold-700 ml-1">
                    {{ sales_stats.percent_retail }}%
                  </h6>
                </div>
                <div>
                  <b-progress
                    :value="sales_stats.percent_retail"
                    max="100"
                    height="8px"
                    variant="success"
                    class="mt-25 mb-25"
                    style="background-color: #e2fbd7"
                  />
                  <div>
                    <h6 class="fw-bold-400 size14 text-success mb-0">
                      {{ sales_stats.total_retail | formatAmount }}
                    </h6>
                  </div>
                </div>
                <div class="d-flex mt-1">
                  <h6 class="fw-bold-400 size18 mb-0 text-dark mr-auto">
                    Non-Ritel
                  </h6>
                  <h6 class="fw-bold-500 size18 text-success fw-bold-700 ml-1">
                    {{ sales_stats.percent_non_retail }}%
                  </h6>
                </div>
                <b-progress
                  :value="sales_stats.percent_non_retail"
                  max="100"
                  height="8px"
                  variant="success"
                  class="mt-25 mb-25"
                  style="background-color: #e2fbd7"
                />
                <div>
                  <h6 class="fw-bold-400 size14 text-success mb-0">
                    {{ sales_stats.total_non_retail | formatAmount }}
                  </h6>
                </div>
              </div>
              <div />
            </b-card-body>
          </b-card>
        </b-col>
        <b-col class="col-lg-0 mb-md-1" cols="6" md="6" lg="3">
          <b-card class="border-10 p-0 h-100" no-body>
            <b-card-body
              class="h-100 d-flex flex-column justify-content-between"
            >
              <div>
                <b-avatar class="mb-1 bg-light bg-lighten-warning" size="45">
                  <img
                    src="@/assets/images/icons/outcome.svg"
                    alt="wallet danger"
                  />
                </b-avatar>
                <h6 class="size14 text-dark fw-bold-400">
                  Total Pengeluaran (Rp)
                  <feather-icon icon="AlertCircleIcon" />
                </h6>
              </div>
              <div>
                <h1 class="fw-bold-500 size32 text-dark mb-2">
                  {{ expenses.total_expenses | formatAmount }}
                </h1>
                <div class="d-flex">
                  <h6 class="fw-bold-400 size18 mb-0 text-dark mr-auto">
                    Supplier
                  </h6>
                  <h6 class="fw-bold-500 size18 text-danger fw-bold-700 ml-1">
                    {{ expenses.percent_supplier }}%
                  </h6>
                </div>
                <div>
                  <b-progress
                    :value="expenses.percent_supplier"
                    max="100"
                    height="8px"
                    variant="danger"
                    class="mt-25"
                    style="background-color: #ffe5d3"
                  />
                  <div>
                    <h6 class="fw-bold-400 size14 text-danger mb-0">
                      {{ expenses.total_supplier | formatAmount }}
                    </h6>
                  </div>
                </div>
                <div class="d-flex mt-1">
                  <h6 class="fw-bold-400 size18 mb-0 text-dark mr-auto">
                    Lainnya
                  </h6>
                  <h6 class="fw-bold-500 size18 text-danger fw-bold-700 ml-1">
                    {{ expenses.percent_non_supplier }}%
                  </h6>
                </div>
                <div>
                  <b-progress
                    :value="expenses.percent_non_supplier"
                    max="100"
                    height="8px"
                    variant="danger"
                    class="mt-25"
                    style="background-color: #ffe5d3"
                  />
                  <div>
                    <h6 class="fw-bold-400 size14 text-danger mb-0">
                      {{ expenses.total_non_supplier | formatAmount }}
                    </h6>
                  </div>
                </div>
              </div>
              <div />
            </b-card-body>
          </b-card>
        </b-col>
        <b-col class="col-lg-0 mb-md-1" cols="6" md="6" lg="3">
          <b-card class="border-10 p-0 h-100" no-body>
            <b-card-body class="h-100 d-flex flex-column">
              <div>
                <b-avatar class="mb-1 bg-light bg-lighten-blue" size="45">
                  <img
                    src="@/assets/images/icons/laba_kotor.svg"
                    alt="wallet blue"
                  />
                </b-avatar>
                <h6 class="size14 text-dark fw-bold-400">
                  Laba Kotor(Rp)
                  <feather-icon icon="AlertCircleIcon" />
                </h6>
              </div>
              <div>
                <h1 class="fw-bold-500 size32 text-dark mb-2 mt-1">
                  {{ gross_profit.total_profit | formatAmount }}
                </h1>
                <h2
                  :class="{
                    'fw-bold-700 size20 text-center mb-2': true,
                    'text-danger': gross_profit.change_percent < 0,
                    'text-success': gross_profit.change_percent >= 0,
                  }"
                >
                  {{ gross_profit.change_percent > 0 ? "+" : ""
                  }}{{ gross_profit.change_percent }}%
                  <span class="ml-1">
                    <feather-icon
                      v-if="gross_profit.change_percent < 0"
                      icon="TrendingDownIcon"
                      size="32"
                    />
                    <feather-icon v-else icon="TrendingUpIcon" size="32" />
                  </span>
                </h2>
                <h6 class="size14 text-dark fw-bold-400">
                  {{
                    gross_profit.change_percent < 0 ? "Menurun" : "Meningkat"
                  }}
                  dari Periode Sebelumnya
                </h6>
              </div>
              <!-- <vue-apex-charts
                type="area"
                height="50"
                width="100%"
                :options="chartOptionsComputed"
                :series="grossProfitChart.series"
              /> -->
            </b-card-body>
          </b-card>
        </b-col>
        <b-col class="col-lg-0 mb-md-1" cols="6" md="6" lg="3">
          <b-card class="border-10 p-0 h-100" no-body>
            <b-card-body class="h-100 d-flex flex-column">
              <div>
                <b-avatar class="mb-1 bg-light bg-lighten-blue-2" size="45">
                  <img
                    src="@/assets/images/icons/laba_bersih.svg"
                    alt="wallet blue"
                  />
                </b-avatar>
                <h6 class="size14 text-dark fw-bold-400">
                  Laba Bersih(Rp)
                  <feather-icon icon="AlertCircleIcon" />
                </h6>
              </div>
              <div>
                <h1 class="fw-bold-500 size32 text-dark mb-2 mt-1">
                  {{ net_profit.total_profit | formatAmount }}
                </h1>
                <h2
                  :class="{
                    'fw-bold-700 size20 text-center mb-2': true,
                    'text-danger': net_profit.change_percent < 0,
                    'text-success': net_profit.change_percent >= 0,
                  }"
                >
                  {{ net_profit.change_percent > 0 ? "+" : ""
                  }}{{ net_profit.change_percent }}%
                  <span class="ml-1">
                    <feather-icon
                      v-if="net_profit.change_percent < 0"
                      icon="TrendingDownIcon"
                      size="32"
                    />
                    <feather-icon v-else icon="TrendingUpIcon" size="32" />
                  </span>
                </h2>
                <h6 class="size14 text-dark fw-bold-400">
                  {{ net_profit.change_percent < 0 ? "Menurun" : "Meningkat" }}
                  dari Periode Sebelumnya
                </h6>
              </div>
              <!-- <vue-apex-charts
                type="area"
                height="50"
                width="100%"
                :options="chartOptionsComputed"
                :series="netProfitChart.series"
              /> -->
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-0 mx-0">
        <b-col cols="12" class="mb-2">
          <b-card class="border-10 mb-0">
            <!-- <BarChart
              :height="400"
              :data="dataSalesVsExpenses"
              :options="chartjsData.latestBarChart.options"
              :plugins="plugins"
            /> -->
            <div id="chart">
              <apexchart
                type="line"
                height="350"
                :options="lineChartOptions"
                :series="lineChartOptions.series"
              ></apexchart>
            </div>
          </b-card>
        </b-col>
        <b-col class="mb-1 mb-md-1 mb-lg-0" cols="12" md="12" lg="5">
          <b-card class="mb-0 border-10">
            <div
              class="d-flex justify-content-between align-items-center flex-wrap"
            >
              <h4 class="size20 text-dark fw-bold-700">Utang Piutang</h4>
              <h6 class="size18">
                {{
                  moment(filterDate.to).isAfter(moment(), "day")
                    ? moment().format("DD MMM YYYY")
                    : moment(filterDate.to).format("DD MMM YYYY")
                }}
              </h6>
            </div>
            <!-- <BarChart
              v-if="!isLoading"
              :height="200"
              :data="dataDebtsReceivables"
              :options="chartjsData.latestBarChart.options"
              class="mb-3"
            /> -->
            <b-row class="mt-3">
              <b-col cols="12" md="3" class="mt-1">
                <h6 class="fw-bold-500 size16 text-uppercase">Utang</h6>
              </b-col>
              <b-col cols="12" md="9">
                <b-progress
                  :value="utang_piutang.utang_percentage"
                  max="100"
                  height="35px"
                  variant="danger"
                  class="mt-25 mb-25"
                  style="background-color: #ffe5d3; border-radius: 0"
                />
                <h6 class="fw-bold-400 size18 text-danger mb-2">
                  {{ utang_piutang.utang | formatAmount }}
                </h6>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="3" class="mt-1">
                <h6 class="fw-bold-500 size16 text-uppercase">Piutang</h6>
              </b-col>
              <b-col cols="12" md="9">
                <b-progress
                  :value="
                    utang_piutang.piutang > 0
                      ? 100 - utang_piutang.utang_percentage
                      : 0
                  "
                  max="100"
                  height="35px"
                  variant="success"
                  class="mt-25 mb-25"
                  style="background-color: #e2fbd7; border-radius: 0"
                />
                <h6 class="fw-bold-400 size18 text-success mb-2">
                  {{ utang_piutang.piutang | formatAmount }}
                </h6>
              </b-col>
            </b-row>
            <!-- <div class="row">
              <div
                v-for="(item,index) in pieChartData"
                :key="index"
                class="mb-1 mr-1 col-12"
              >
                <h6 class="mb-0 d-flex" @click="$router.push({ name: 'debt.index', query: { cta: item } })" role="button">
                  <div
                    class="mr-25"
                    style="width:14px;height:14px;border-radius:50%"
                    :style="`background: ${ index == 0 ? '#FFB200' : '#02A0FC' }`"
                    
                  />
                  <span>{{ item }} :&nbsp;</span><span class="ml-25" v-if="item === 'Utang'"> {{ parseFloat(utangNominal) | formatAmount }}</span> <span class="ml-25" v-else> {{ parseFloat(piutangNominal) | formatAmount }}</span>
                </h6>
              </div>
            </div> -->
          </b-card>
        </b-col>
        <b-col cols="12" md="12" lg="7">
          <b-card class="mb-0 h-100 border-10 px-0" style="position: relative">
            <b-tabs content-class="mt-1">
              <b-tab title="Produk Terlaris">
                <b-list-group flush>
                  <b-list-group-item>
                    <b-row>
                      <b-col cols="2">
                        <h6 class="text-dark text-darken-4 fw-bold-700">No</h6>
                      </b-col>
                      <b-col cols="7">
                        <h6 class="text-dark text-darken-4 fw-bold-700">
                          Nama Produk
                        </h6>
                      </b-col>
                      <b-col cols="3">
                        <h6
                          class="text-dark text-darken-4 fw-bold-700 text-right"
                        >
                          Jumlah
                        </h6>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item
                    v-for="(item, index) in best_seller"
                    :key="index"
                  >
                    <b-row>
                      <b-col cols="2">
                        <h6 class="text-dark fw-bold-500">
                          {{ index + 1 }}
                        </h6>
                      </b-col>
                      <b-col cols="7">
                        <h6 class="text-dark fw-bold-500">
                          {{ item.sku_code }}
                        </h6>
                      </b-col>
                      <b-col cols="3">
                        <h6 class="text-dark fw-bold-500 text-right">
                          {{ item.sales_count }}
                        </h6>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
                <!-- <div class="w-100 float--button">
                  <b-button
                    class="bg-white"
                    @click="$router.push({ name: 'stock.index', query: { cta: 'produk_terlaris' } })"
                  >
                    Lihat semua
                  </b-button>
                </div> -->
              </b-tab>
              <b-tab title="Stok Menipis">
                <b-list-group flush>
                  <b-list-group-item>
                    <b-row>
                      <b-col cols="2">
                        <h6 class="text-dark text-darken-4 fw-bold-700">No</h6>
                      </b-col>
                      <b-col cols="7">
                        <h6 class="text-dark text-darken-4 fw-bold-700">
                          Nama Produk
                        </h6>
                      </b-col>
                      <b-col cols="3">
                        <h6
                          class="text-dark text-darken-4 fw-bold-700 text-right"
                        >
                          Jumlah
                        </h6>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item
                    v-for="(item, index) in runout_soon"
                    :key="index"
                  >
                    <b-row role="button" @click="handleRedirectStock(item)">
                      <b-col cols="2">
                        <h6 class="text-dark fw-bold-500">
                          {{ (currentPageRunoutSoon - 1) * 10 + index + 1 }}
                        </h6>
                      </b-col>
                      <b-col cols="7">
                        <h6 class="text-dark fw-bold-500">
                          {{ item.product && item.product.name }}
                        </h6>
                      </b-col>
                      <b-col cols="3">
                        <h6 class="text-dark fw-bold-500 text-right">
                          {{ item.stock }}
                        </h6>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
                <!-- <div class="w-100 float--button">
                  <b-button
                    class="bg-white"
                    @click="$router.push({ name: 'stock.index', query: { cta: 'produk_menipis' } })"
                  >
                    Lihat semua
                  </b-button>
                </div> -->
                <div>
                  <b-row class="mx-0 align-items-center">
                    <b-col>
                      <pagination
                        class="mb-0"
                        :data="rows_runout_soon"
                        :limit="4"
                        align="center"
                        @pagination-change-page="getRunoutSoonProducts"
                      />
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
              <b-tab title="Dead Stok">
                <b-list-group flush>
                  <b-list-group-item>
                    <b-row>
                      <b-col cols="2">
                        <h6 class="text-dark text-darken-4 fw-bold-700">No</h6>
                      </b-col>
                      <b-col cols="7">
                        <h6 class="text-dark text-darken-4 fw-bold-700">
                          Nama Produk
                        </h6>
                      </b-col>
                      <b-col cols="3">
                        <h6
                          class="text-dark text-darken-4 fw-bold-700 text-right"
                        >
                          Jumlah
                        </h6>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item
                    v-for="(item, index) in dead_stock"
                    :key="index"
                  >
                    <b-row>
                      <b-col cols="2">
                        <h6 class="text-dark fw-bold-500">
                          {{ (currentPageDeadStok - 1) * 10 + index + 1 }}
                        </h6>
                      </b-col>
                      <b-col cols="7">
                        <h6 class="text-dark fw-bold-500">
                          {{ item.sku_code }}
                        </h6>
                      </b-col>
                      <b-col cols="3">
                        <h6 class="text-dark fw-bold-500 text-right">
                          {{ item.total_stock }}
                        </h6>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
                <!-- <div class="w-100 float--button">
                  <b-button
                    class="bg-white"
                    @click="$router.push({ name: 'stock.index', query: { cta: 'produk_menipis' } })"
                  >
                    Lihat semua
                  </b-button>
                </div> -->
                <div>
                  <b-row class="mx-0 align-items-center">
                    <b-col>
                      <pagination
                        class="mb-0"
                        :data="rows_dead_stock"
                        :limit="4"
                        align="center"
                        @pagination-change-page="getDeadStockProducts"
                      />
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <ModalExport
      :getProduk="getProduk"
      :optionsProduk="optionsProduk"
      :idModal="'export-report'"
      :exportReport="exportReport"
      :isLoadingExport="isLoadingExport"
      @filterExport="(value) => (filterExport = value)"
    />
    <ProductReport
      :exportReport="exportReport"
      :isLoading="isLoadingExport"
      @productUuid="getProductUuid"
    />
  </div>
</template>

<script>
import {
  BContainer,
  VBToggle,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BAvatar,
  BProgress,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

import moment from "moment";

import {
  checkPermission,
  successNotification,
  errorNotification,
  downloadNotification,
} from "@/auth/utils";
import BarChart from "@/components/Report/BarChart.vue";
import ProductReport from "@/components/Report/ProductReport.vue";
import ChartjsComponentDoughnutChart from "@/components/Report/ChartjsComponentDoughnutChart.vue";

import FilterReport from "@/components/FilterReport.vue";
import FileSaver from "file-saver";
import chartjsData from "./chartjsData";
import { areaChartOptions } from "./chartOptions";

import ModalExport from "@/components/Report/Download/Modal.vue";
import { CloudLightningIcon } from "vue-feather-icons";

export default {
  title() {
    return "Pembayaran";
  },
  components: {
    BDropdown,
    BDropdownItem,
    ProductReport,
    BContainer,
    BarChart,
    BRow,
    BCol,
    FilterReport,
    BCard,
    BCardBody,
    apexchart: VueApexCharts,
    VueApexCharts,
    BProgress,
    BAvatar,
    ChartjsComponentDoughnutChart,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    BButton,
    ModalExport,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  setup() {
    return {
      downloadNotification,
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    const newData = new Date();
    const startData = moment(newData).startOf("month").format("YYYY-MM-DD");
    const endData = moment(newData).endOf("month").format("YYYY-MM-DD");
    return {
      // downloadProgress: 0,
      // downloadSuccess: false,
      filter: {
        start_date: startData,
        end_date: endData,
        filter_date_type: "",
        product_uuid: "",
      },
      filterBestSellerProduct: {
        start_date: startData,
        end_date: endData,
        filter_date_type: "",
        per_page: 10,
      },
      filterRunoutSoonProduct: {
        start_date: startData,
        end_date: endData,
        filter_date_type: "",
        per_page: 10,
      },
      filterDeadStockProduct: {
        start_date: startData,
        end_date: endData,
        filter_date_type: "",
        per_page: 10,
      },
      moment,
      chartjsData,
      pieChartData: ["Utang", "Piutang"],
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20;
            };
            /* eslint-enable */
          },
        },
      ],
      dataSalesVsExpenses: {
        labels: [],
        datasets: [
          {
            label: "Pengeluaran",
            barThickness: 15,
            data: [],
            backgroundColor: "#FF3A29",
            borderColor: "#ffffff",
            borderWidth: 0,
          },
          {
            label: "Pendapatan",
            barThickness: 15,
            data: [],
            backgroundColor: "#34B53A",
            borderColor: "#ffffff",
            borderWidth: 0,
          },
        ],
      },
      lineChartOptions: {
        series: [
          {
            name: "Pengeluaran",
            data: [],
          },
          {
            name: "Pendapatan",
            data: [],
          },
        ],
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        colors: ["#E52A34", "#28C76F"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        // grid: {
        //   row: {
        //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        //     opacity: 0.5
        //   },
        // },
        xaxis: {
          categories: [],
        },
      },
      dataDebtsReceivables: {
        labels: ["Utang", "Piutang"],
        datasets: [
          {
            labels: ["Utang", "Piutang"],
            data: [90, 10],
            backgroundColor: ["#FFB200", "#02A0FC"],
            borderWidth: 0,
            barThickness: 100,
            pointStyle: "rectRounded",
          },
        ],
      },
      grossProfitChart: {
        series: [
          {
            name: "Total",
            data: [],
          },
        ],
      },
      netProfitChart: {
        series: [
          {
            name: "Total",
            data: [],
          },
        ],
      },
      listDownloadReport: [
        {
          name: "Laporan Pembayaran Ke Pemasok",
          type: "supplier_payment",
        },
        {
          name: "Laporan Pembayaran Lainnya",
          type: "other_payment",
        },
        {
          name: "Laporan Utang",
          type: "utang",
        },
        {
          name: "Laporan Piutang",
          type: "piutang",
        },
        {
          name: "Laporan Pembelian Stok Pemasok",
          type: "incoming_stock_supplier",
        },
        {
          name: "Laporan Pembelian Stok Gudang",
          type: "incoming_stock_warehouse",
        },
        {
          name: "Laporan Kasir",
          type: "cashier",
        },
        {
          name: "Laporan Stok Aktual",
          type: "actual_stock",
        },
        {
          name: "Laporan Penjualan",
          type: "order",
        },
        {
          name: "Laporan Stok Opname",
          type: "stock_opname",
        },
      ],
      chartOptions: null,
      color: "danger",
      expenses: {},
      product_stats: {},
      gross_profit: {},
      net_profit: {},
      sales_stats: {},
      sales_vs_expanses: [],
      utang_piutang: {},
      isLoading: false,
      isLoadingExport: false,
      piutangNominal: 0,
      utangNominal: 0,
      filterExport: null,
      optionsProduk: [],
      best_seller: {},
      runout_soon: {},
      dead_stock: {},
      rows_runout_soon: {},
      rows_dead_stock: {},
      currentPageDeadStok: 1,
      currentPageRunoutSoon: 1,
      filterDate: {},
    };
  },
  computed: {
    chartOptionsComputed() {
      if (this.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(areaChartOptions));
        options.theme.monochrome.color = $themeColors[this.color];
        return options;
      }
      return this.chartOptions;
    },
  },
  watch: {
    sales_vs_expanses: {
      handler(value) {
        const labels = value?.sales_vs_expenses.map((e) => e.date);
        const sales = value?.sales_vs_expenses.map((e) => e.sales);
        const expenses = value?.sales_vs_expenses.map((e) => e.expenses);
        this.dataSalesVsExpenses = {
          labels,
          datasets: [
            {
              ...this.dataSalesVsExpenses.datasets[0],
              data: expenses,
            },
            {
              ...this.dataSalesVsExpenses.datasets[1],
              data: sales,
            },
          ],
        };
        this.lineChartOptions = {
          chart: {
            toolbar: {
              show: false,
            },
            fontFamily: "Manrope, sans-serif",
          },
          xaxis: {
            labels: {
              rotate: 0,
            },
            categories: labels,
            title: {
              text: "Tanggal",
              offsetY: 110,
              // offsetY: window.innerWidth >= 1456 ? 100 : 125,
              offsetX: -25,
              style: {
                fontSize: "14px",
                fontFamily: "Manrope, sans-serif",
                fontWeight: 700,
              },
            },
            tickAmount: 6,
          },
          yaxis: {
            title: {
              text: "Jumlah",
              style: {
                fontSize: "14px",
                fontFamily: "Manrope, sans-serif",
                fontWeight: 700,
              },
            },
          },
          series: [
            {
              ...this.lineChartOptions.series[0],
              data: expenses,
            },
            {
              ...this.lineChartOptions.series[1],
              data: sales,
            },
          ],
          legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -5,
          },
        };
      },
      deep: true,
    },
    utang_piutang: {
      handler(value) {
        this.piutangNominal = value.piutang;
        this.utangNominal = value.utang;
        this.dataDebtsReceivables.datasets[0].data = [
          value.utang,
          value.piutang,
        ];
      },
      deep: true,
    },
    filter: {
      handler() {
        this.getReport();
      },
      deep: true,
    },
    filterBestSellerProduct: {
      handler() {
        this.getBestSellerProducts();
      },
      deep: true,
    },
    filterRunoutSoonProduct: {
      handler() {
        this.getRunoutSoonProducts();
      },
      deep: true,
    },
    filterDeadStockProduct: {
      handler() {
        this.getDeadStockProducts();
      },
      deep: true,
    },
  },
  created() {
    this.getReport();
    this.getProduk();
    this.getBestSellerProducts();
    this.getRunoutSoonProducts();
    this.getDeadStockProducts();
  },
  methods: {
    handleRedirectStock(item) {
      this.$router.push({ name: "master-product.detail", params: { id: item?.product?.uuid }, })
    },
    flattenObject(obj) {
      const flattenedObject = {};

      for (let key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          const nestedObject = this.flattenObject(obj[key]);
          for (let nestedKey in nestedObject) {
            if (nestedKey == "label") {
              flattenedObject[key] = nestedObject[nestedKey];
            }
          }
        } else {
          if (obj[key] !== "semua") {
            flattenedObject[key] = obj[key];
          }
        }
      }

      return flattenedObject;
    },
    async exportReport() {
      const flattenObject = this.flattenObject(this.filterExport);
      const filter = {
        ...this.filter,
        ...flattenObject,
      };

      this.isLoadingExport = true;
      // this.downloadProgress = 0;
      this.$bvModal.hide("export-report");
      downloadNotification(this, "Sedang Mengunduh");
      const queryParams = filter;

      const response = await this.$http.get("/report/export", {
        responseType: "blob",
        params: queryParams,
        // onDownloadProgress: (progressEvent) => {
        //   this.downloadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        // },
      });

      const dateDownload = new Date();

      const timestamp = moment(dateDownload).format("YYYYMMDD");
      let fileName = "";

      if (filter.report_type === "stock_actual") {
        fileName = `laporan-stok-aktual-${timestamp}.xlsx`;
      }
      if (filter.report_type === "supplier_payment") {
        fileName = `laporan-pembayaran-ke-supplier-${timestamp}.xlsx`;
      }
      if (filter.report_type === "other_payment") {
        fileName = `laporan-pembayaran-lainnya-${timestamp}.xlsx`;
      }
      if (filter.report_type === "utang") {
        fileName = `laporan-utang-${timestamp}.xlsx`;
      }
      if (filter.report_type === "piutang") {
        fileName = `laporan-piutang-${timestamp}.xlsx`;
      }
      if (filter.report_type === "incoming_stock") {
        fileName = `laporan-pembelian-${timestamp}.xlsx`;
      }
      if (filter.report_type === "incoming_stock_supplier") {
        fileName = `laporan-pembelian-stok-supplier-${timestamp}.xlsx`;
      }
      if (filter.report_type === "incoming_stock_warehouse") {
        fileName = `laporan-pembelian-stok-gudang-${timestamp}.xlsx`;
      }
      if (filter.report_type === "product_stock") {
        fileName = `kartu-stok-${timestamp}.xlsx`;
      }
      if (filter.report_type === "cashier") {
        fileName = `laporan-kasir-${timestamp}.xlsx`;
      }
      if (filter.report_type === "actual_stock") {
        fileName = `laporan-stok-aktual-${timestamp}.xlsx`;
      }
      if (filter.report_type === "stock_opname") {
        fileName = `laporan-stok-opname-${timestamp}.xlsx`;
      }
      if (filter.report_type === "order") {
        fileName = `laporan-penjualan-${timestamp}.xlsx`;
      }
      if (filter.report_type === "piutang_payment") {
        fileName = `laporan-pembayaran-piutang-${timestamp}.xlsx`;
      }
      if (filter.report_type === "gross_profit") {
        fileName = `laporan-laba-kotor-${timestamp}.xlsx`;
      }
      if (filter.report_type === "transfer_stock") {
        fileName = `laporan-transfer-stok-${timestamp}.xlsx`;
      }
      FileSaver.saveAs(response.data, fileName);
      // this.downloadProgress = 100
      // setTimeout(() => {
      this.isLoadingExport = false;
      //   this.downloadSuccess = true;
      // }, 2000);
      // downloadNotification(this, 'Unduhan Selesai')
      // console.log(response, ' report')
    },
    formatAmount(value) {
      if (typeof value !== "number") {
        return value;
      }
      const formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      });
      return formatter.format(value).replace(/^(\D+)/, "Rp ");
    },
    getStartDate(value) {
      this.filter.start_date = value;
      this.filterBestSellerProduct.start_date = value;
      this.filterRunoutSoonProduct.start_date = value;
      this.filterDeadStockProduct.start_date = value;
    },
    getEndDate(value) {
      this.filter.end_date = value;
      this.filterBestSellerProduct.end_date = value;
      this.filterRunoutSoonProduct.end_date = value;
      this.filterDeadStockProduct.end_date = value;
    },
    getProductUuid(val) {
      this.filter.product_uuid = val.label;
    },
    getFilterDateType(val) {
      this.filter.filter_date_type = val;
      this.filterBestSellerProduct.filter_date_type = val;
      this.filterRunoutSoonProduct.filter_date_type = val;
      this.filterDeadStockProduct.filter_date_type = val;
    },
    async getBestSellerProducts() {
      const queryParams = this.filterBestSellerProduct;
      await this.$http
        .get("/report/product-best-seller", {
          params: queryParams,
        })
        .then((result) => {
          this.rows_best_seller = result.data.data;
          this.best_seller = result.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getRunoutSoonProducts(page = 1) {
      this.currentPageRunoutSoon = page;
      const queryParams = this.filterRunoutSoonProduct;
      queryParams.page = page;
      await this.$http
        .get("/report/product-runout-soon", {
          params: queryParams,
        })
        .then((result) => {
          this.rows_runout_soon = result.data.data;
          this.runout_soon = result.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDeadStockProducts(page = 1) {
      this.currentPageDeadStok = page;
      const queryParams = this.filterDeadStockProduct;
      queryParams.page = page;
      await this.$http
        .get("/report/product-dead-stock", {
          params: queryParams,
        })
        .then((result) => {
          this.rows_dead_stock = result.data.data;
          this.dead_stock = result.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getReport() {
      this.isLoading = true;
      const queryParams = this.filter;
      await this.$http
        .get("/report", {
          params: queryParams,
        })
        .then((result) => {
          const items = result.data.data;
          this.filterDate = items.report_date;
          this.expenses = items.expenses;
          // this.product_stats = items.product_stats
          this.gross_profit = items.gross_profit;
          this.net_profit = items.net_profit;
          this.sales_stats = items.sales_stats;
          this.sales_vs_expanses = items;
          this.utang_piutang = items.utang_piutang;
          this.grossProfitChart.series[0] = {
            ...this.grossProfitChart.series[0],
            data: items.gross_profit.per_dates.map((e) => e.total),
          };
          this.netProfitChart.series[0] = {
            ...this.netProfitChart.series[0],
            data: items.net_profit.per_dates.map((e) => e.total),
          };
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    addData(type) {
      this.is_edit = true;
      this.$bvModal.show(type);
    },
    getProduk() {
      this.$store
        .dispatch("cashier/getData", { uuid: "", params: "" })
        .then((result) => {
          this.optionsProduk = result.data.data.data.map((e) => {
            return {
              label: e.uuid,
              title: e.name,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
.nav-tabs {
  .nav-link {
    &.active {
      color: #333348;
      color: #34b53a;
    }
    &::after {
      background: #333348 !important;
      border-radius: 4px 4px 0px 0px;
      height: 3px;
    }
  }
}
.float--button {
  background-image: linear-gradient(
    0deg,
    rgb(255, 255, 255) 68.19%,
    rgba(255, 255, 255, 0) 137.1%
  );
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 85px;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 26px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
</style>
